<template>
  <v-row justify="center">
    <v-col dense cols="12" md="4" lg="3"
      v-for="(collection, index) in storeCollections"
      :key="index"
    >
      <v-card class="rounded-xl" color="grey-lighten-1">
        <v-img max-height="200" :src="collection.banner"></v-img>
        <v-card-title style="word-break: normal;">{{collection.name}}</v-card-title>
        <v-card-text>
          <v-row align="center" class="mx-0">
            <div>
              {{collection.description}}
            </div>
          </v-row>
          <v-row dense>
            <!-- Each Recipe -->
            <v-col cols="12" v-for="recipe in collection.recipes.slice(0,2)" :key="recipe.id">
              <v-card class="rounded-xl">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <div>
                    <v-card-title v-text="recipe.name"></v-card-title>
                    <v-card-text>
                      <v-chip class="my-3">{{`${recipe.ingredients.length} ingredient${recipe.ingredients.length === 1 ? '' : 's'}`}}</v-chip>
                      <v-chip class="">{{`${recipe.steps.length} step${recipe.steps.length === 1 ? '' : 's'}`}}</v-chip>
                    </v-card-text>
                  </div>
                  <v-avatar class="ma-3 rounded-xl" size="75" tile v-if="recipe.thumbnail">
                    <v-img :src="recipe.thumbnail"></v-img>
                  </v-avatar>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-col cols="12" v-if="collection.recipes.length > 2">
            <v-card class="rounded-xl">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title v-text="`+ ${collection.recipes.slice(2).length} more`"></v-card-title>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  import { mapGetters, mapState } from 'vuex';

  export default {
    name: 'Collections',
    data: () => ({

    }),
    computed: {
      ...mapState({
        storeCollections: 'collections',
      })
    }
  }
</script>